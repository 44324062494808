.v-rating .v-icon {
  padding: 0.5rem;
  border-radius: 50%;
  user-select: none;
}
.v-rating--readonly .v-icon {
  pointer-events: none;
}
.v-rating--dense .v-icon {
  padding: 0.1rem;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_rating.css.map */