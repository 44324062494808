/* Theme */
.theme--light.v-table {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light.v-table thead tr:first-child {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.theme--light.v-table thead th {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-table tbody tr:not(:first-child) {
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--light.v-table tbody tr[active] {
  background: #f5f5f5;
}
.theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background: #eee;
}
.theme--light.v-table tfoot tr {
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--dark.v-table {
  background-color: #424242;
  color: #fff;
}
.theme--dark.v-table thead tr:first-child {
  border-bottom: 1px solid rgba(255,255,255,0.12);
}
.theme--dark.v-table thead th {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-table tbody tr:not(:first-child) {
  border-top: 1px solid rgba(255,255,255,0.12);
}
.theme--dark.v-table tbody tr[active] {
  background: #505050;
}
.theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background: #616161;
}
.theme--dark.v-table tfoot tr {
  border-top: 1px solid rgba(255,255,255,0.12);
}
.v-table__overflow {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
table.v-table {
  border-radius: 2px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
table.v-table thead td:not(:nth-child(1)),
table.v-table tbody td:not(:nth-child(1)),
table.v-table thead th:not(:nth-child(1)),
table.v-table tbody th:not(:nth-child(1)),
table.v-table thead td:first-child,
table.v-table tbody td:first-child,
table.v-table thead th:first-child,
table.v-table tbody th:first-child {
  padding: 0 24px;
}
table.v-table thead tr {
  height: 56px;
}
table.v-table thead th {
  font-weight: 500;
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  user-select: none;
}
table.v-table thead th.sortable {
  pointer-events: auto;
}
table.v-table thead th > div {
  width: 100%;
}
table.v-table tbody tr {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background;
}
table.v-table tbody td,
table.v-table tbody th {
  height: 48px;
}
table.v-table tbody td {
  font-weight: 400;
  font-size: 13px;
}
table.v-table .input-group--selection-controls {
  padding: 0;
}
table.v-table .input-group--selection-controls .input-group__details {
  display: none;
}
table.v-table .input-group--selection-controls.checkbox .v-icon {
  left: 50%;
  transform: translateX(-50%);
}
table.v-table .input-group--selection-controls.checkbox .input-group--selection-controls__ripple {
  left: 50%;
  transform: translate(-50%, -50%);
}
table.v-table tfoot tr {
  height: 48px;
}
table.v-table tfoot tr td {
  padding: 0 24px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_tables.css.map */