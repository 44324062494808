.theme--light.v-date-picker-table th,
.theme--light.v-date-picker-table .v-date-picker-table--date__week {
  color: rgba(0,0,0,0.38);
}
.theme--dark.v-date-picker-table th,
.theme--dark.v-date-picker-table .v-date-picker-table--date__week {
  color: rgba(255,255,255,0.5);
}
.v-date-picker-table {
  position: relative;
  padding: 0 12px;
  height: 242px;
}
.v-date-picker-table table {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  top: 0;
  table-layout: fixed;
  width: 100%;
}
.v-date-picker-table td,
.v-date-picker-table th {
  text-align: center;
  position: relative;
}
.v-date-picker-table th {
  font-size: 12px;
}
.v-date-picker-table--date .v-btn {
  height: 32px;
  width: 32px;
}
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 12px;
}
.v-date-picker-table .v-btn.v-btn--active {
  color: #fff;
}
.v-date-picker-table--month td {
  width: 33.333333%;
  height: 56px;
  vertical-align: middle;
  text-align: center;
}
.v-date-picker-table--month td .v-btn {
  margin: 0 auto;
  max-width: 160px;
  min-width: 40px;
  width: 100%;
}
.v-date-picker-table--date th {
  padding: 8px 0;
  font-weight: 600;
}
.v-date-picker-table--date td {
  width: 45px;
}
.v-date-picker-table__events {
  height: 8px;
  left: 0;
  position: absolute;
  text-align: center;
  white-space: pre;
  width: 100%;
}
.v-date-picker-table__events > div {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 0 1px;
  width: 8px;
}
.v-date-picker-table--date .v-date-picker-table__events {
  bottom: 6px;
}
.v-date-picker-table--month .v-date-picker-table__events {
  bottom: 8px;
}
.v-date-picker-table--disabled {
  pointer-events: none;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_date-picker-table.css.map */