.application--is-rtl .v-snack__content .v-btn {
  margin: 0 24px 0 0;
}
.v-snack {
  position: fixed;
  display: flex;
  align-items: center;
  color: #fff;
  pointer-events: none;
  z-index: 1000;
  font-size: 14px;
  left: 0;
  right: 0;
}
.v-snack--absolute {
  position: absolute;
}
.v-snack--top {
  top: 0;
}
.v-snack--bottom {
  bottom: 0;
}
.v-snack__wrapper {
  display: flex;
  align-items: center;
  background-color: #323232;
  pointer-events: auto;
  width: 100%;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.v-snack__content {
  width: 100%;
  height: 48px;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.v-snack__content .v-btn {
  color: #fff;
  flex: 0 0 auto;
  padding: 8px;
  margin: 0 0 0 24px;
  height: auto;
  min-width: auto;
  width: auto;
}
.v-snack__content .v-btn__content {
  margin: -2px;
}
.v-snack__content .v-btn::before {
  display: none;
}
.v-snack--multi-line .v-snack__content {
  height: 80px;
  padding: 24px;
}
.v-snack--vertical .v-snack__content {
  height: 112px;
  padding: 24px 24px 14px;
  flex-direction: column;
  align-items: stretch;
}
.v-snack--vertical .v-snack__content .v-btn.v-btn {
  justify-content: flex-end;
  padding: 0;
  margin-left: 0;
  margin-top: 24px;
}
.v-snack--vertical .v-snack__content .v-btn__content {
  flex: 0 0 auto;
  margin: 0;
}
.v-snack--auto-height .v-snack__content {
  height: auto;
}
@media only screen and (min-width: 600px) {
  .application--is-rtl .v-snack__content .v-btn:first-of-type {
    margin-left: 0;
    margin-right: 42px;
  }
}
@media only screen and (min-width: 600px) {
  .v-snack__wrapper {
    width: auto;
    max-width: 568px;
    min-width: 288px;
    margin: 0 auto;
    border-radius: 2px;
  }
  .v-snack--left .v-snack__wrapper {
    margin-left: 0;
  }
  .v-snack--right .v-snack__wrapper {
    margin-right: 0;
  }
  .v-snack--left,
  .v-snack--right {
    margin: 0 24px;
  }
  .v-snack--left.v-snack--top,
  .v-snack--right.v-snack--top {
    transform: translateY(24px);
  }
  .v-snack--left.v-snack--bottom,
  .v-snack--right.v-snack--bottom {
    transform: translateY(-24px);
  }
  .v-snack__content .v-btn:first-of-type {
    margin-left: 42px;
  }
}
.v-snack-transition-enter-active,
.v-snack-transition-leave-active {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-snack-transition-enter-active .v-snack__content,
.v-snack-transition-leave-active .v-snack__content {
  transition: opacity 0.3s linear 0.1s;
}
.v-snack-transition-enter .v-snack__content {
  opacity: 0;
}
.v-snack-transition-enter-to .v-snack__content,
.v-snack-transition-leave .v-snack__content {
  opacity: 1;
}
.v-snack-transition-enter.v-snack.v-snack--top,
.v-snack-transition-leave-to.v-snack.v-snack--top {
  transform: translateY(calc(-100% - 8px));
}
.v-snack-transition-enter.v-snack.v-snack--bottom,
.v-snack-transition-leave-to.v-snack.v-snack--bottom {
  transform: translateY(100%);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_snackbars.css.map */