/* Themes */
.theme--light.v-picker__title {
  background: #e0e0e0;
}
.theme--dark.v-picker__title {
  background: #616161;
}
.theme--light.v-picker__body {
  background: #fff;
}
.theme--dark.v-picker__body {
  background: #424242;
}
.v-picker {
  border-radius: 2px;
  contain: layout style;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  position: relative;
}
.v-picker--full-width {
  display: flex;
}
.v-picker__title {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 16px;
}
.v-picker__title__btn {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-picker__title__btn:not(.v-picker__title__btn--active) {
  opacity: 0.6;
  cursor: pointer;
}
.v-picker__title__btn:not(.v-picker__title__btn--active):hover:not(:focus) {
  opacity: 1;
}
.v-picker__title__btn--readonly {
  pointer-events: none;
}
.v-picker__title__btn--active {
  opacity: 1;
}
.v-picker__body {
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-picker__body > div {
  width: 100%;
}
.v-picker__body > div.fade-transition-leave-active {
  position: absolute;
}
.v-picker--landscape .v-picker__title {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 170px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.v-picker--landscape .v-picker__body,
.v-picker--landscape .v-picker__actions {
  margin-left: 170px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_pickers.css.map */