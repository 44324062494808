.theme--light.v-bottom-nav {
  background-color: #fff;
}
.theme--light.v-bottom-nav .v-btn:not(.v-btn--active) {
  color: rgba(0,0,0,0.54) !important;
}
.theme--dark.v-bottom-nav {
  background-color: #424242;
}
.theme--dark.v-bottom-nav .v-btn:not(.v-btn--active) {
  color: rgba(255,255,255,0.7) !important;
}
.v-item-group.v-bottom-nav {
  bottom: 0;
  box-shadow: 0 3px 14px 2px rgba(0,0,0,0.12);
  display: flex;
  left: 0;
  justify-content: center;
  transform: translate(0, 60px);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-item-group.v-bottom-nav--absolute {
  position: absolute;
}
.v-item-group.v-bottom-nav--active {
  transform: translate(0, 0);
}
.v-item-group.v-bottom-nav--fixed {
  position: fixed;
  z-index: 4;
}
.v-item-group.v-bottom-nav .v-btn {
  background: transparent !important;
  border-radius: 0;
  box-shadow: none !important;
  font-weight: 400;
  height: 100%;
  margin: 0;
  max-width: 168px;
  min-width: 80px;
  padding: 8px 12px 10px;
  text-transform: none;
  width: 100%;
  flex-shrink: 1;
}
.v-item-group.v-bottom-nav .v-btn .v-btn__content {
  flex-direction: column-reverse;
  font-size: 12px;
  white-space: nowrap;
  will-change: font-size;
}
.v-item-group.v-bottom-nav .v-btn .v-btn__content i.v-icon {
  color: inherit;
  margin-bottom: 4px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-item-group.v-bottom-nav .v-btn .v-btn__content span {
  line-height: 1;
}
.v-item-group.v-bottom-nav .v-btn--active {
  padding-top: 6px;
}
.v-item-group.v-bottom-nav .v-btn--active:before {
  background-color: transparent;
}
.v-item-group.v-bottom-nav .v-btn--active .v-btn__content {
  font-size: 14px;
}
.v-item-group.v-bottom-nav .v-btn--active .v-btn__content .v-icon {
  transform: none;
}
.v-item-group.v-bottom-nav--shift .v-btn__content {
  font-size: 14px;
}
.v-item-group.v-bottom-nav--shift .v-btn {
  transition: all 0.3s;
  min-width: 56px;
  max-width: 96px;
}
.v-item-group.v-bottom-nav--shift .v-btn--active {
  min-width: 96px;
  max-width: 168px;
}
.v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content .v-icon {
  transform: scale(1, 1) translate(0, 8px);
}
.v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content > span:not(.v-badge) {
  color: transparent;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_bottom-navs.css.map */