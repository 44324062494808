.theme--light.v-jumbotron .v-jumbotron__content {
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-jumbotron .v-jumbotron__content {
  color: #fff;
}
.v-jumbotron {
  display: block;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-jumbotron__wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: inherit;
  width: 100%;
}
.v-jumbotron__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  contain: strict;
  transition: inherit;
}
.v-jumbotron__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  will-change: transform;
  transition: inherit;
}
.v-jumbotron__content {
  height: 100%;
  position: relative;
  transition: inherit;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_jumbotrons.css.map */