.theme--light.v-timeline:before {
  background: rgba(0,0,0,0.12);
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: #fff;
}
.theme--light.v-timeline .v-timeline-item .v-card:before {
  border-right-color: rgba(0,0,0,0.12);
}
.theme--dark.v-timeline:before {
  background: rgba(255,255,255,0.12);
}
.theme--dark.v-timeline .v-timeline-item__dot {
  background: #424242;
}
.theme--dark.v-timeline .v-timeline-item .v-card:before {
  border-right-color: rgba(0,0,0,0.12);
}
.v-timeline-item {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right),
.v-timeline-item--left {
  flex-direction: row;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:before,
.v-timeline-item--left .v-card:before,
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:after,
.v-timeline-item--left .v-card:after {
  transform: rotate(180deg);
  left: 100%;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-timeline-item__opposite,
.v-timeline-item--left .v-timeline-item__opposite {
  margin-left: 96px;
  text-align: left;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-timeline-item__opposite .v-card:before,
.v-timeline-item--left .v-timeline-item__opposite .v-card:before,
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-timeline-item__opposite .v-card:after,
.v-timeline-item--left .v-timeline-item__opposite .v-card:after {
  transform: rotate(0);
  left: -10px;
}
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-card:before,
.v-timeline-item--right .v-card:before,
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-card:after,
.v-timeline-item--right .v-card:after {
  right: 100%;
}
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-timeline-item__opposite,
.v-timeline-item--right .v-timeline-item__opposite {
  margin-right: 96px;
  text-align: right;
}
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-timeline-item__opposite .v-card:before,
.v-timeline-item--right .v-timeline-item__opposite .v-card:before,
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-timeline-item__opposite .v-card:after,
.v-timeline-item--right .v-timeline-item__opposite .v-card:after {
  transform: rotate(180deg);
  right: -10px;
}
.v-timeline-item__dot,
.v-timeline-item__inner-dot {
  border-radius: 50%;
}
.v-timeline-item__dot {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  align-self: center;
  position: absolute;
  height: 38px;
  left: calc(50% - 19px);
  width: 38px;
}
.v-timeline-item__dot .v-timeline-item__inner-dot {
  height: 30px;
  margin: 4px;
  width: 30px;
}
.v-timeline-item__dot--small {
  height: 24px;
  left: calc(50% - 12px);
  width: 24px;
}
.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 18px;
  margin: 3px;
  width: 18px;
}
.v-timeline-item__dot--large {
  height: 52px;
  left: calc(50% - 26px);
  width: 52px;
}
.v-timeline-item__dot--large .v-timeline-item__inner-dot {
  height: 42px;
  margin: 5px;
  width: 42px;
}
.v-timeline-item__inner-dot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-timeline-item__body {
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  max-width: calc(50% - 48px);
}
.v-timeline-item .v-card:before,
.v-timeline-item .v-card:after {
  content: '';
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #000;
  top: calc(50% - 10px);
}
.v-timeline-item .v-card:after {
  border-right-color: inherit;
}
.v-timeline-item .v-card:before {
  top: calc(50% - 10px + 2px);
}
.v-timeline-item__opposite {
  flex: 1 1 auto;
  align-self: center;
  max-width: calc(50% - 48px);
}
.v-timeline-item--fill-dot .v-timeline-item__inner-dot {
  height: inherit;
  margin: 0;
  width: inherit;
}
.v-timeline {
  padding-top: 24px;
  position: relative;
}
.v-timeline:before {
  bottom: 0;
  content: '';
  height: 100%;
  left: calc(50% - 1px);
  position: absolute;
  top: 0;
  width: 2px;
}
.v-timeline--align-top .v-timeline-item {
  position: relative;
}
.v-timeline--align-top .v-timeline-item__dot {
  top: 6px;
}
.v-timeline--align-top .v-timeline-item__dot--small {
  top: 12px;
}
.v-timeline--align-top .v-timeline-item__dot--large {
  top: 0;
}
.v-timeline--align-top .v-timeline-item .v-card:before {
  top: calc(0% + 10px + 2px);
}
.v-timeline--align-top .v-timeline-item .v-card:after {
  top: calc(0% + 10px);
}
.v-timeline--dense:before {
  left: 18px;
}
.v-timeline--dense .v-timeline-item:nth-child(odd):not(.v-timeline-item--right),
.v-timeline--dense .v-timeline-item--left {
  flex-direction: row-reverse;
}
.v-timeline--dense .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:before,
.v-timeline--dense .v-timeline-item--left .v-card:before,
.v-timeline--dense .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:after,
.v-timeline--dense .v-timeline-item--left .v-card:after {
  right: initial;
  left: -10px;
  transform: none;
}
.v-timeline--dense .v-timeline-item__dot {
  left: 0;
}
.v-timeline--dense .v-timeline-item__dot--small {
  left: 7px;
}
.v-timeline--dense .v-timeline-item__dot--large {
  left: -7px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 64px);
}
.v-timeline--dense .v-timeline-item__opposite {
  display: none;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_timeline.css.map */