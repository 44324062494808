/* Theme */
.theme--light.v-system-bar {
  background-color: #e0e0e0;
  color: rgba(0,0,0,0.54);
}
.theme--light.v-system-bar .v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-system-bar--lights-out {
  background-color: rgba(255,255,255,0.7) !important;
}
.theme--dark.v-system-bar {
  background-color: #000;
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-system-bar .v-icon {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-system-bar--lights-out {
  background-color: rgba(0,0,0,0.2) !important;
}
.v-system-bar {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
}
.v-system-bar .v-icon {
  font-size: 16px;
}
.v-system-bar--fixed,
.v-system-bar--absolute {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}
.v-system-bar--fixed {
  position: fixed;
}
.v-system-bar--absolute {
  position: absolute;
}
.v-system-bar--status .v-icon {
  margin-right: 4px;
}
.v-system-bar--window .v-icon {
  font-size: 20px;
  margin-right: 8px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_system-bars.css.map */