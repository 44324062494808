.theme--light.v-calendar-daily {
  background-color: #fff;
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day {
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
  color: #000;
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #424242;
}
.theme--light.v-calendar-daily .v-calendar-daily__day {
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: #e0e0e0 1px solid;
}
.theme--light.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}
.theme--dark.v-calendar-daily {
  background-color: #303030;
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily_head-day {
  border-right: #9e9e9e 1px solid;
  border-bottom: #9e9e9e 1px solid;
  color: #fff;
}
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #eee;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day {
  border-right: #616161 1px solid;
  border-bottom: #616161 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: #616161 1px solid;
}
.theme--dark.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}
.v-calendar-daily {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.v-calendar-daily__head {
  flex: none;
  display: flex;
}
.v-calendar-daily__intervals-head {
  flex: none;
  width: 44px;
}
.v-calendar-daily_head-day {
  flex: 1 1 auto;
  width: 0;
}
.v-calendar-daily_head-weekday {
  user-select: none;
  padding: 4px 4px 4px 8px;
  font-size: 14px;
}
.v-calendar-daily_head-day-label {
  user-select: none;
  font-size: 40px;
  padding: 0px 4px 4px 8px;
  line-height: 40px;
  cursor: pointer;
}
.v-calendar-daily_head-day-label:hover {
  text-decoration: underline;
}
.v-calendar-daily__body {
  flex: 1 1 60%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}
.v-calendar-daily__scroll-area {
  overflow-y: scroll;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}
.v-calendar-daily__pane {
  width: 100%;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
}
.v-calendar-daily__day-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}
.v-calendar-daily__intervals-body {
  flex: none;
  user-select: none;
  width: 44px;
}
.v-calendar-daily__interval {
  text-align: center;
  border-bottom: none;
}
.v-calendar-daily__interval-text {
  display: block;
  position: relative;
  top: -6px;
  font-size: 10px;
}
.v-calendar-daily__day {
  flex: 1;
  width: 0;
  position: relative;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_calendar-daily.css.map */